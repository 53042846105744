import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { GthAuthService, GthRemoteConfigService } from '@gth-legacy';
import { Conversation } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import { GthConversationModel } from '@sentinels/models';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  get getConversationDialogEnabled() {
    return this.remote.getConversationDialogEnabled();
  }

  constructor(
    private afdb: AngularFireDatabase,
    private auth: GthAuthService,
    private remote: GthRemoteConfigService,
  ) { }

  getConversation(
    conversationId: string,
    userId: string,
  ): Observable<GthConversationModel> {
    return new Observable<GthConversationModel>((observer) => {
      const conversationPath = `${DBUtil.Conversation}/${userId}/${conversationId}/`;
      this.afdb.database.ref(conversationPath).on(
        'value',
        (snap) => {
          const conversation = snap.val() as Conversation;
          const conversationModel = conversation ?
            new GthConversationModel(conversationId, conversation) : undefined;
          observer.next(conversationModel);
        },
      );
    });
  }

  getConversations(): Observable<GthConversationModel[]> {
    return this.auth.userModel$.pipe(
      map((user) => (user ? user.uid : undefined)),
      switchMap((userId: string | undefined) => {
        if (!userId) {
          return of([]);
        }
        return this.getMessages$(userId);
      }),
    );
  }

  getNonTeamConversations$(): Observable<GthConversationModel[]> {
    return this.getConversations().pipe(
      map((conversations) => {
        return conversations.filter((c) => !c.isTeamBroadcastMessage);
      }),
    );
  }

  private getMessages$(userId: string) {
    return new Observable<GthConversationModel[]>((observer) => {
      this.afdb.database.ref(`/conversations/${userId}/`).on('value', (snapshot) => {
        const data = snapshot.val();
        const conversations: GthConversationModel[] = [];
        // eslint-disable-next-line guard-for-in
        for (const key in data) {
          const conversation = new GthConversationModel(key, data[key]);
          conversations.push(conversation);
        }
        /** Remove team conversations */
        const directMessageConversations = conversations
          .filter((c) => c.id.startsWith('-'));
        observer.next(directMessageConversations);
      });
    });
  }
}
