import { Message } from '@index/interfaces';

import { GthModel } from './model';

export class GthMessageModel extends GthModel<Message> {
  get message(): Message {
    return this.model;
  }

  get id() {
    return this.message.id;
  }

  get isRead() {
    return this.message.isRead;
  }

  get isDelivered() {
    return !!this._isDelivered;
  }

  get messageSent() {
    return this._messageSent;
  }

  get participantId() {
    return this.message.participantId;
  }

  get text() {
    return this.message.text;
  }

  private _messageSent?: Date;
  private _isDelivered = true;

  constructor(id: string, model: Message) {
    super(id, model);

    if (model.messageSent) {
      this._messageSent = new Date(model.messageSent);
    }
  }

  static getNewModel(userId: string, text: string) {
    return new GthMessageModel('', {
      participantId: userId,
      text,
      messageSent: new Date(),
      isRead: false,
    });
  }

  /**
   * Mark message as delivered
   * @param {boolean} delivered Value
   */
  markDelivered(delivered = true) {
    this._isDelivered = delivered;
  }

  /**
   * Mark message as read
   * @param {boolean} read Value
   */
  markRead(read = true) {
    this.message.isRead = read;
  }
}
