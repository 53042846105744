import { Conversation, User } from '@index/interfaces';

import { GthMessageModel } from './message';
import { GthModel } from './model';
import { GthUserModel } from './user';

export class GthConversationModel extends GthModel<Conversation> {
  private get conversation(): Conversation {
    return this.model;
  }

  get id() {
    return this._id;
  }

  get muted() {
    return this.conversation.muted;
  }

  get blocked() {
    return this.conversation.blocked;
  }

  get reported() {
    return this.conversation.reported;
  }

  get participants() {
    return this._participants;
  }

  get messages(): GthMessageModel[] {
    return this._messages;
  }

  get isTeamBroadcastMessage() {
    return this._isTeamBroadcastMessage;
  }

  get unreadMessages(): GthMessageModel[] {
    return this.messages.filter((m) => !m.isRead);
  }

  private _messages: GthMessageModel[] = [];
  private _participants: GthUserModel[] = [];
  private _isTeamBroadcastMessage = false;

  constructor(id: string, model: Conversation) {
    super(id, model);

    this._messages = [];
    this._participants = [];

    if (this.model.participants) {
      this.model.participants.forEach((p: User | GthUserModel) => {
        const userModel = (p as GthUserModel).model;
        const _p = userModel ?? p;
        const participant = new GthUserModel(p.uid, _p);
        this._participants.push(participant);

        if (participant.team) {
          this._isTeamBroadcastMessage = true;
        }
      });
    }

    // eslint-disable-next-line guard-for-in
    for (const key in model.messages) {
      const message = new GthMessageModel(key, model.messages[key]);
      this._messages.push(message);
    }

    this.sortMessages();
  }

  static getNewConversation(participants: GthUserModel[]) {
    return new GthConversationModel('', {
      participants: participants as unknown as User[],
      messages: [],
      muted: false,
    });
  }

  /**
   * Gets the newest message within the conversation
   * @return {GthMessageModel} Newest message
   */
  getLatestMessage() {
    const messages = this.messages;
    if (messages && messages.length > 0) {
      this.sortMessages();
      const lastMessage = messages[messages.length - 1];
      return lastMessage;
    }
    return undefined;
  }

  /**
   * Gets list of users that are not the current user.
   * @param {GthUserModel} user Authenticated User
   * @return {GthUserModel} user
   */
  getParticipants(user: GthUserModel) {
    const userId = user.uid;
    return this.participants.filter((p) => p.uid !== userId);
  }

  /**
   * Returns boolean on if user is in the array
   * @param {string} userId id of the user
   * @return {boolean} if a user is part of the conversation
   */
  isParticipant(userId: string) {
    return !!this.participants.filter((p) => p.uid === userId).length;
  }

  /**
   * Gets participant, within conversation, by id
   * @param {string} id Id of participant
   * @return {GthUserModel} user within conversation
   */
  getParticipantById(id: string) {
    return this.participants.find((p) => p.uid === id);
  }

  /**
   * Determines whether two conversations are equal
   * @param {GthConversationModel} conversation Conversation we are checking equality with
   * @return {boolean}
   */
  equals(conversation: GthConversationModel) {
    if (this.id && conversation.id) {
      return this.id === conversation.id;
    } else {
      const conversationParticpants = conversation.participants
        .map((p) => p.uid)
        .sort()
        .join(',');
      const participants = this.participants
        .map((p) => p.uid)
        .sort()
        .join(',');
      return conversationParticpants === participants;
    }
  }

  /**
   * Marks all messages within conversation as delivered.
   */
  markMessagesDelivered() {
    this._messages.forEach((m) => m.markDelivered());
  }

  /**
   * Marks all messages within conversation as read.
   */
  markMessagesRead() {
    this._messages.forEach((m) => m.markRead());
  }

  /**
   * Sets the mute state of the conversation
   * @param {boolean} muted Value
   */
  mute(muted = true) {
    this.conversation.muted = muted;
  }

  /**
   * Sets the block state of the conversation
   * @param {boolean} blocked Value
   */
  block(blocked = true) {
    this.conversation.blocked = blocked;
  }

  /**
   * Sets the report state of the conversation
   * @param {boolean} reported Value
   */
  report(reported = true) {
    this.conversation.reported = reported;
  }

  private sortMessages() {
    this._messages.sort((m1, m2) => {
      if (m1.messageSent && m2.messageSent) {
        return m1.messageSent.getTime() > m2.messageSent.getTime() ? 1 : -1;
      }

      return -1;
    });
  }
}
